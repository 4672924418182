'use client'

import { CustomImageContextProvider } from '@/contexts/CustomImageContext'
import { CustomImageContextType } from '@/types/contextTypes'
import { NonUrlContextProvider } from '@/contexts/NonUrlContext'
import { useCart } from '@/hooks/useCart'
import { CartItemType, ImageSide } from '@/utils/APIRouteTypes'
import ProductPrice from '../detailComponents/productPrice'
import AggregatedImage from '../imageViewer/aggregatedImage'
import AggregatedImage2 from '../imageViewer/aggregatedImage2'
import { Button } from '../ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select'


type props = {
    item: CartItemType
}

export default function CartDrawerItem({ item }: props) {


    const contextValue = item.context as CustomImageContextType
    const isFront = contextValue.frontImages.length >= contextValue.backImages.length
    const boundingBox = isFront ? item.boundingBoxFront : item.boundingBoxBack
    const colorName = item.selectedColor?.name
    const imgSrc = item.selectedColor?.images.filter(y => isFront ? y.side === ImageSide.FRONT : y.side === ImageSide.BACK)[0].image
    const cart = useCart()

    const handleQtyUpdate = (qty: string) => {
        cart.updateQty(item.id, Number(qty))
    }

    const handleRemove = () => {
        cart.removeItem(item.id)
    }

    return (
        <CustomImageContextProvider defaultProps={contextValue} isStatic >
            <NonUrlContextProvider sizes={item.selectedSize ? [item.selectedSize] : []} colors={item.selectedColor ? [item.selectedColor] : []} sampleImages={[]}>
                <div className='flex border-b w-full px-1 py-2 gap-4 max-h-[200px] overflow-hidden my-2'>
                    <div className='max-w-24'>
                        {boundingBox && item.colorsExist ? <AggregatedImage side={isFront ? "front" : 'back'} imgSrc={imgSrc || ''} boundingBox={boundingBox} imgHeight={item.imgHeight} imgWidth={item.imgWidth} tshirtAltText='Wish List Item' className='-my-2' /> :
                            <AggregatedImage2 boundingBox={boundingBox} cameraBox={item.selectedSize?.cameraBox} isCircular={item.selectedSize?.isCircular || false} />
                        }
                    </div>
                    <div className=' flex items-start flex-col w-full'>
                        <p>
                            <h3 className='font-bold -mb-2 line-clamp-1'>
                                {item.productDetails.name}
                            </h3>
                            <span className='text-sm text-slate-500'>
                                {colorName} - {item.selectedSize?.name}
                            </span>
                        </p>
                        <div className='flex justify-between w-full'>
                            <div className='w-full'>
                                <Select value={String(item.quantity)} disabled={cart.loading} onValueChange={handleQtyUpdate}>
                                    <SelectTrigger className="w-fit mt-1" >
                                        <SelectValue placeholder="Quantity" />
                                    </SelectTrigger>
                                    <SelectContent className="z-[999]">
                                        {Array.from(Array(9).keys()).map(i => (
                                            <SelectItem key={i + 1} value={String(i + 1)}>
                                                {String(i + 1)}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <Button onClick={handleRemove} variant='link' className='p-0' disabled={cart.loading}>Remove</Button>
                            </div>

                            <ProductPrice price={item.productDetails.price} MRP={item.productDetails.MRP} hideTax className='me-2' right />

                        </div>
                    </div>
                </div>
            </NonUrlContextProvider>
        </CustomImageContextProvider>
    )
}


export const CartDrawerItemSkeleton = () => {
    return (

        <div className='flex border-b w-full py-2 px-2'>
            <div className="skeleton h-32 w-24"></div>
            <div className='ms-1 flex justify-center items-start flex-col'>

                <div className="skeleton h-7 w-32 my-1"></div>

                <div className="skeleton h-9 w-10 my-1"></div>
                <div className="skeleton h-4 w-20 my-1"></div>
            </div>
        </div>

    )
}